<template>
  <div class="tabs">
    <div
      ref="tabsHeader"
      :class="[
        'tabs__header d-flex flex-nowrap',
        { 'scroll-shadow--right': scrollShadowRight },
        { 'scroll-shadow--left': scrollShadowLeft },
      ]"
      @scroll.passive="handleScroll"
    >
      <component
        :is="renderingComponent"
        v-for="(tab, index) in tabsList"
        :id="isActive(index) ? 'activeTab' : tab.label"
        :key="tab.label"
        :ref="tab.label"
        :to="tab.link"
        variant="link"
        :class="[
          'tab__title reset-btn-styling text-nowrap',
          { 'tab__title--active fw-bold': isActive(index) },
        ]"
        @click="selectTab(index, tab.label)"
      >
        {{ tab.label }}
      </component>
    </div>

    <div
      v-for="(tab, index) in tabsList"
      v-show="isActive(index) & !renderAsLinks"
      :key="tab.label"
      class="tab__content mt-24"
    >
      <slot :name="`tabContent-${index}`" :active="isActive(index)" />
    </div>
  </div>
</template>

<script>
import BaseButton from '~/components/BaseButton'
import { NuxtLink } from '#components'

export default {
  components: {
    BaseButton,
    NuxtLink,
  },
  props: {
    tabsList: {
      type: Array,
      required: true,
    },
    renderAsLinks: {
      type: Boolean,
      default: false,
    },
    selectedTab: {
      type: Number,
      default: 0,
    },
    onTabChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      activeTab: this.selectedTab,
      scrollableTabs: false,
      scrolling: false,
      scrollShadowLeft: false,
      scrollShadowRight: false,
    }
  },
  computed: {
    renderingComponent() {
      return this.renderAsLinks ? 'NuxtLink' : 'BaseButton'
    },
  },
  watch: {
    selectedTab(newTab, oldTab) {
      if (newTab !== oldTab) {
        this.activeTab = newTab
      }
    },
  },
  mounted() {
    this.checkIfTabsScrollable()
    this.scrollToActiveTab()
  },
  methods: {
    isActive(index) {
      return index === this.activeTab
    },
    selectTab(index, refName) {
      this.activeTab = index

      this.onTabChange(index, refName)

      try {
        this.$refs.tabsHeader.scrollLeft = this.$refs[refName][0].offsetLeft
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    checkIfTabsScrollable() {
      try {
        const { offsetWidth, scrollWidth } = this.$refs.tabsHeader

        this.scrollableTabs = offsetWidth + 16 < scrollWidth
        this.scrollShadowRight = this.scrollableTabs
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    handleScroll(event) {
      try {
        if (this.scrolling || !this.scrollableTabs) return

        const { offsetWidth, scrollLeft, scrollWidth } = event.target

        this.scrollShadowLeft = scrollLeft > 0
        this.scrollShadowRight = offsetWidth + scrollLeft < scrollWidth - 16
        this.scrolling = true

        setTimeout(() => {
          this.scrolling = false
        }, 10)
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    scrollToActiveTab() {
      try {
        const refName = this.tabsList[this.activeTab].label

        this.$refs.tabsHeader.scrollTo(
          this.$refs[refName][0].$el.offsetLeft / 2.5,
          0,
        )
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs__header {
  border-bottom: 1px solid $grey-300;
}

.tab__title {
  min-width: 75px;
  min-height: 42px;
  padding: 8px 16px;
  color: $grey-800;
  line-height: 24px;
  border-radius: 0;
  text-decoration: none;

  &--active {
    cursor: default !important;
    color: $green-400;
    margin-bottom: -1px;
    border-bottom: 2px solid $green-400;
  }

  &:focus,
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  &:not(.tab__title--active):hover {
    color: $green-400;
    background-color: $green-50;
  }
}

@include media-breakpoint-down(sm) {
  .tabs__header {
    border-bottom: 1px solid $grey-300;
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .scroll-shadow--right {
    margin-right: -16px;

    &::after {
      content: '';
      position: absolute;
      right: -16px;
      width: 56px;
      height: 40px;
      background: linear-gradient(
        to left,
        $white 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    .tab__title {
      &:last-child {
        margin-right: 16px;
      }
    }
  }

  .scroll-shadow--left {
    margin-left: -16px;

    &::before {
      content: '';
      position: absolute;
      width: 56px;
      height: 40px;
      background: linear-gradient(
        to right,
        $white 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}
</style>
